.newsCard {
  height: 100%;
}

.newsHeader {
  text-align: center;
  background-color: #194d55;
}

.newsCard::before {
  content: "";
  display: block;
  background-color: #82c89b;
  height: 5px;
}

.newsHeaderText.newsHeaderText {
  color: #fefffe;
  font-family: Mate, serif;
  text-decoration: none;
}

.newsHeaderText.newsHeaderText:hover {
  text-decoration: underline;
}

.newsList {
  padding-top: 1rem;
}

.newsContainer {
  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  gap: 0.5rem;
  padding: 1rem;
}

.newsContainer a {
  text-decoration: none;
}

.newsContainer a:hover {
  text-decoration: underline;
}

.newsDate {
  text-align: center;
  font-size: 0.825rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: -5px;
}

.newsBlob {
  display: grid;
  place-items: center;
  margin-top: -2%;
  margin-bottom: -3%;
}

.newsPageContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  margin: 1%;
}

.newsCardContainer {
  width: clamp(200px, 90%, 400px);
  height: clamp(90%, 95%, 100%);
}

.newsCardContainer a {
  text-decoration: none;
}

.newsCardContainer a:hover {
  text-decoration: underline;
}

.newsCardImage {
  min-height: 140px;
}

div.MuiCardContent-root.newsCardContent > div > svg {
  color: #051b2d;
}

@media (width < 2000px) {
  .newsList {
    display: grid;
    gap: 1rem;
  }

  .newsList > div:nth-child(6) {
    display: none;
  }
}

@media (width < 1650px) {
  .newsContainer > img {
    width: 80px;
    height: 80px;
  }

  .newsList {
    gap: 2rem;
  }
}

@media (width < 1400px) {
  .newsList {
    gap: 1rem;
  }
}

@media (width < 1200px) {
  .newsList {
    grid-template-columns: repeat(3, 1fr);
  }

  .newsList > div:nth-child(6) {
    display: block;
  }
}

@media (width < 900px) {
  .newsList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width < 850px) {
  .newsPageContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width < 700px) {
  .newsList {
    grid-template-columns: 1fr;
  }

  .newsList > div:nth-child(4),
  .newsList > div:nth-child(5),
  .newsList > div:nth-child(6) {
    display: none;
  }
}

@media (width < 650px) {
  .newsPageContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width < 450px) {
  .newsPageContainer {
    grid-template-columns: 1fr;
  }
}
