.container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(9, 1fr);
  gap: 2.5rem;
  padding: 1.5rem 2.5rem;
}

.container > div:nth-child(1) {
  grid-area: 1 / 1 / 7 / 3;
}

.container > div:nth-child(2) {
  grid-area: 7 / 1 / 10 / 3;
}

.container > div:nth-child(3) {
  grid-area: 1 / 3 / 4 / 5;
}

.container > div:nth-child(4) {
  grid-area: 4 / 3 / 7 / 5;
}

.container > div:nth-child(5) {
  grid-area: 7 / 3 / 10 / 5;
}

.container > div:nth-child(6) {
  grid-area: 1 / 5 / 4 / 7;
}

.container > div:nth-child(7) {
  grid-area: 4 / 5 / 7 / 7;
}

.container > div:nth-child(8) {
  grid-area: 7 / 5 / 10 / 7;
}

.basketballFieldMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.basketballFieldMain a {
  text-decoration: none;
}

.basketballFieldMain a:hover {
  text-decoration: underline;
}

.mainPageCardMedia {
  width: 100%;
  height: clamp(13rem, 16vw, 25rem);
}

.hoverPictureContainer {
  position: absolute;
  transform: translateX(150px);
  z-index: 1;
}

.hoverPicture {
  border-radius: 5px;
}

.iconLink {
  width: 24px;
  color: #1a4e55;
}

@media (width < 1200px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(8, 1fr);
  }

  .container > div:nth-child(1) {
    grid-area: 1 / 1 / 3 / 5;
  }

  .container > div:nth-child(2) {
    grid-area: 3 / 1 / 5 / 3;
  }

  .container > div:nth-child(3) {
    grid-area: 3 / 3 / 5 / 5;
  }

  .container > div:nth-child(4) {
    grid-area: 5 / 1 / 7 / 3;
  }

  .container > div:nth-child(5) {
    grid-area: 5 / 3 / 7 / 5;
  }

  .container > div:nth-child(6) {
    grid-area: 7 / 1 / 9 / 3;
  }

  .container > div:nth-child(7) {
    grid-area: 7 / 3 / 9 / 5;
  }

  .container > div:nth-child(8) {
    display: none;
  }

  .mainPageCardMedia {
    height: clamp(13rem, 23vw, 25rem);
  }
}

@media (width < 900px) {
  .container {
    row-gap: 0;
  }
}

@media (width < 600px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .container > div {
    width: 90vw;
  }

  .container > div:nth-child(8) {
    display: block;
  }
}
